.package-wrap {
    background: #fafafa;
    padding: 15px 10px;
    border-radius: 5px;
    &.dark {
        background: rgba(203, 204, 210, 0.02);
    }
    &.active {
        background: rgba(85, 105, 255, 0.1);
    }
    .title {
        margin: 0;
        font-size: 15px;
        text-align: center;
        margin-bottom: 5px;
    }
    .price {
        font-size: 22px;
        font-weight: 700;
        margin-top: 0;
        margin-bottom: 10px;
        text-align: center;
    }
    ul {
        list-style: none;
        margin: 0;
        padding: 0;
        margin-bottom: 10px;
        li {
            text-align: center;
            margin-bottom: 5px;
        }
    }
}
.text-center {
    text-align: center;
}
.btn-style {
    &.MuiButton-root {
        display: inline-flex;
        align-items: center;
        padding: 3px 10px;
        font-weight: 400;
        font-size: 14px;
    }
}
.w-100 {
    .MuiFormControl-root {
        width: 100%;
    }
}

.table-actions {
    display: flex;
    align-items: center;
    justify-content: center;
    li {
        margin-left: 5px;
        button {
            &.error {
                background-color: rgba(255, 25, 67, 0.1);
                color: #ff1943;
            }
        }
    }
}

.withdrawal-information {
    background: #fafafa;
    padding: 25px;
    border-radius: 5px;
    margin: 0;
    @media (max-width: 767px) {
        padding: 15px;
    }
    &.dark {
        background: rgba(203, 204, 210, 0.02);
        li {
            border-color: rgba($color: #fff, $alpha: 0.05);
            &:last-child {
                border-bottom-color: rgba($color: #fff, $alpha: 0.05);
            }
        }
    }
    li {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px 15px;
        border: 1px solid rgba($color: #fff, $alpha: 0.05);
        border-bottom: none;
        flex-wrap: wrap;
        span {
            display: inline-block;
            word-break: break-all;
        }
        &:last-child {
            border-bottom: 1px solid rgba($color: #fff, $alpha: 0.05);
        }
    }
}
.profile-menu {
    display: flex;
    align-items: center;
    list-style: none;
    margin: 0;
    padding: 0;
    border-bottom: 1px solid #e5e5e5;
    flex-wrap: wrap;
    //   margin-bottom: 30px;

    &.PurpleFlowTheme {
        color: rgb(242, 169, 0);
        li {
            a {
                &.active {
                    color: rgb(242, 169, 0);
                    &::before {
                        background: rgb(242, 169, 0);
                    }
                }
            }
        }
    }
    &.GreyGooseTheme {
        li {
            a {
                &.active {
                    color: rgb(28, 52, 140);
                    &::before {
                        background: rgb(28, 52, 140);
                    }
                }
            }
        }
    }
    &.PureLightTheme {
        li {
            a {
                &.active {
                    color: rgb(68, 84, 204);
                    &::before {
                        background: rgb(68, 84, 204);
                    }
                }
            }
        }
    }
    &.NebulaFighterTheme {
        li {
            a {
                color: rgba($color: #fff, $alpha: 0.9);
                &.active {
                    color: rgb(112, 99, 192);
                    &::before {
                        background: rgb(112, 99, 192);
                    }
                }
            }
        }
    }
    &.GreenFieldsTheme {
        li {
            a {
                color: rgba($color: #fff, $alpha: 0.9);
                &.active {
                    color: rgb(54, 132, 92);
                    &::before {
                        background: rgb(54, 132, 92);
                    }
                }
            }
        }
    }
    &.DarkSpacesTheme {
        li {
            a {
                color: rgba($color: #fff, $alpha: 0.9);
                &.active {
                    color: rgb(242, 169, 0);
                    &::before {
                        background: rgb(242, 169, 0);
                    }
                }
            }
        }
    }
    li {
        margin-right: 20px;
        @media (max-width: 991px) {
            margin-bottom: 10px;
        }
        a {
            display: flex;
            align-items: center;
            padding-bottom: 15px;
            color: rgba($color: #000000, $alpha: 0.9);
            text-decoration: none;
            position: relative;
            &.active {
                color: rgba(85, 105, 255, 0.85);
                &::before {
                    position: absolute;
                    left: 0;
                    bottom: -1px;
                    width: 100%;
                    height: 3px;
                    content: "";
                }
            }
            svg {
                margin-right: 10px;
            }
        }
    }
}
.google-auth-wrap {
    border: 1px solid #e5e5e5;
    border-radius: 5px;
    .auth-title {
        margin: 0px;
        min-height: 50px;
        display: inline-flex;
        align-items: center;
        border-bottom: 1px solid #e5e5e5;
        width: 100%;
        padding: 5px 20px;
    }
    .google-auth-body {
        padding: 30px;
        text-align: center;
        p {
            margin: 30px 0;
        }
        .icon {
            min-height: 80px;
            min-width: 80px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            background: rgba(85, 105, 255, 0.2);
            border-radius: 50%;
            position: relative;
            svg {
                color: rgba(85, 105, 255, 1);
                font-size: 40px;
            }
            .checked {
                font-size: 30px;
                right: -5px;
                bottom: -5px;
                position: absolute;
            }
        }
    }
}
.qrcode-wrap {
    text-align: center;
    span {
        display: inline-block;
        max-width: 200px;
        line-height: 20px;
        color: black;
        &.dark {
            color: #fff;
        }
    }
}
.copy-code {
    position: relative;
    display: flex;
    align-items: center;
    border-radius: 5px;
    font-size: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    flex-wrap: wrap;
    border: 1px solid gray;
    &.PurpleFlowTheme {
        border-color: rgb(242, 169, 0);
    }
    &.GreyGooseTheme {
        border-color: rgb(28, 52, 140);
    }
    &.PureLightTheme {
        border-color: rgb(68, 84, 204);
    }
    &.NebulaFighterTheme {
        border-color: rgb(112, 99, 192);
    }
    &.GreenFieldsTheme {
        border-color: rgb(54, 132, 92);
    }
    &.DarkSpacesTheme {
        border-color: rgb(242, 169, 0);
    }
    .text {
        padding: 5px 15px;
        min-height: 45px;
        display: flex;
        align-items: center;
        width: calc(100% - 75px);
        line-height: 15px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;

        @media (max-width: 991px) {
            width: 100%;
            border-radius: 5px;
        }
    }
    .copy {
        min-width: 75px;
        min-height: 45px;
        display: inline-flex;
        align-items: center;
        background: rgba(85, 105, 255, 1);
        color: #fff;
        text-transform: uppercase;
        cursor: pointer;
        font-weight: 700;
        border-radius: 5px;
        justify-content: center;
        font-size: 12px;
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;

        &.PurpleFlowTheme {
            background: rgb(242, 169, 0);
        }
        &.GreyGooseTheme {
            background: rgb(28, 52, 140);
        }
        &.PureLightTheme {
            background: rgb(68, 84, 204);
        }
        &.NebulaFighterTheme {
            background: rgb(112, 99, 192);
        }
        &.GreenFieldsTheme {
            background: rgb(54, 132, 92);
        }
        &.DarkSpacesTheme {
            background: rgb(242, 169, 0);
        }
        @media (max-width: 991px) {
            min-height: 30px;
            min-width: 100%;
            margin-top: 10px;
            border-radius: 5px;
        }
    }
}
.m-0 {
    margin: 0;
}
div:where(.swal2-container) {
    z-index: 9999 !important;
}
.text-success {
    color: #57ca22 !important;
}
.text-error {
    color: #ff1943 !important;
}
.bg-error {
    background: rgba(255, 25, 67, 0.1) !important;
}
.text-center {
    text-align: center;
}
.treeview-wrapper {
    .treeview-item {
        .MuiTreeItem-content {
            background: #fafafa;
            border-radius: 0px;
            margin-bottom: 2px;
            border: 1px solid #e5e5e5;
            .MuiTreeItem-label {
                .MuiButton-root {
                    min-height: 30px;
                    min-width: 60px;
                    padding: 5px 10px;
                    border-radius: 4px;
                    font-size: 12px;
                }
            }
        }
    }
}
.header-area {
    background: #fff;
    .logo {
        display: inline-block;
        background: #003;
        padding: 5px;
    }
    .container {
        .MuiGrid-item {
            padding-top: 0;
        }
    }
}
ul {
    margin: 0;
    padding: 0;
    list-style: none;
}
.container {
    &.MuiGrid-container {
        max-width: 1200px;
        margin: auto;
        @media (max-width: 991px) {
            width: 100%;
            .MuiGrid-item {
                padding: 0px 15px;
                box-sizing: border-box;
            }
        }
    }
}
.mainmenu {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    li {
        margin-right: 20px;
        &:last-child {
            margin-right: 0;
        }
        a {
            font-size: 16px;
            color: rgba($color: #000000, $alpha: 0.9);
            text-decoration: none;
            font-weight: 500;
            &.btn-style {
                background: #5569ff;
                color: #fff;
            }
        }
    }
}

.text-avatar {
    display: flex;
    align-items: center;
    justify-content: center;
    .MuiAvatar-root {
        margin-right: 0;
    }
}
.main-container {
    background: #fff;
}
.weare-wrapper {
    background-color: #fff;
    background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iMTIiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGNpcmNsZSBjeD0iMSIgY3k9IjEiIHI9IjEiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDEwKSIgZmlsbD0iI0U2RUFFRCIgZmlsbC1ydWxlPSJldmVub2RkIi8+PC9zdmc+");
    display: flex;
    align-items: center;
    min-height: 500px;
    position: relative;
    z-index: 1;
    @media (max-width: 991px) {
        min-height: 300px;
    }
    &::before {
        position: absolute;
        left: 0;
        top: 0;
        width: 50%;
        height: 100%;
        content: "";
        background: #fff;
        z-index: -1;
    }
    .title {
        color: #86f9b3;
        font-size: 18px;
        letter-spacing: 1px;
        line-height: 28px;
        font-style: italic;
        margin-bottom: 1em;
        margin-top: 0;
    }
    .subtitle {
        color: #484848;
        font-size: 40px;
        line-height: 50px;
        margin-bottom: 18px;
        padding-right: 30px;
        margin-top: 0;
        span {
            color: orange;
        }
        @media (max-width: 991px) {
            font-size: 30px;
            line-height: 40px;
            padding-right: 0;
        }
    }
}
.main-container {
    position: relative;
    .circle-wrap {
        height: 80em;
        overflow: auto;
        overflow-x: hidden;
        overflow-y: hidden;
        position: absolute;
        right: 0;
        top: 0;
        width: 40em;
        z-index: 11;
        @media (max-width: 991px) {
            width: 20em;
            height: 40em;
            display: none;
        }
        img {
            left: 18px;
            position: absolute;
            top: 18px;
            &.coins___B8b8i {
                left: 0;
                top: 0;
                animation: rotating___3RJO7 120s linear infinite;
                animation-delay: 0.5s;
            }
        }
    }
}
@keyframes rotating___3RJO7 {
    0% {
        transform: rotate(1turn);
    }

    to {
        transform: rotate(0deg);
    }
}
.whoare-wrapper {
    min-height: 500px;
    display: flex;
    align-items: center;
    @media (max-width: 991px) {
        min-height: 300px;
    }
    .title {
        color: #07233b;
        font-size: 48px;
        line-height: 58px;
        margin-bottom: 15px;
        font-weight: 500;
        @media (max-width: 991px) {
            font-size: 30px;
            line-height: 40px;
        }
    }
    p {
        color: #484848;
        font-size: 18px;
        line-height: 28px;
        margin-bottom: 0;
        margin-top: 0;
        font-weight: 500;
        @media (max-width: 991px) {
            font-size: 16px;
            line-height: 24px;
        }
    }
}
.whatare-wrapper {
    padding-bottom: 120px;
    .title {
        color: #07233b;
        font-size: 48px;
        line-height: 58px;
        margin-bottom: 15px;
        font-weight: 500;
        @media (max-width: 991px) {
            font-size: 30px;
            line-height: 40px;
        }
    }
    p {
        color: #484848;
        font-size: 18px;
        line-height: 28px;
        margin-bottom: 0;
        margin-top: 0;
        font-weight: 500;
        @media (max-width: 991px) {
            font-size: 16px;
            line-height: 24px;
        }
    }
}

.list-wrap {
    li {
        margin-bottom: 50px;
        &:last-child {
            margin-bottom: 0;
        }
        h4 {
            color: #484848;
            font-size: 30px;
            line-height: 40px;
            margin-bottom: 1.5em;
            position: relative;
            margin-top: 0;
            @media (max-width: 991px) {
                font-size: 24px;
                line-height: 30px;
            }
            &::before {
                background-color: #f1a801;
                bottom: -15px;
                content: "";
                height: 6px;
                position: absolute;
                width: 80px;
            }
        }
        p {
            color: #484848;
            font-size: 18px;
            line-height: 28px;
            margin-bottom: 0;
            @media (max-width: 991px) {
                font-size: 16px;
                line-height: 24px;
            }
        }
    }
}
.footer-area {
    background-color: #07233b;
    padding: 20px 0;
    position: relative;
    z-index: 1;
    @media (max-width: 991px) {
        padding: 30px 0;
    }
    text-align: center;
    p {
        margin: 0;
        color: #fff;
    }
}

.menu-item {
    svg {
        path {
            fill: #fff;
        }
    }
}
.responsive-item {
    &.PurpleFlowTheme {
        svg {
            path {
                fill: rgba(34, 51, 84, 0.5);
            }
        }
    }
    &.GreyGooseTheme {
        svg {
            path {
                fill: rgba(34, 51, 84, 0.5);
            }
        }
    }
    &.PureLightTheme {
        svg {
            path {
                fill: rgba(34, 51, 84, 0.5);
            }
        }
    }
    &.NebulaFighterTheme {
        svg {
            path {
                fill: #fff;
            }
        }
    }
    &.GreenFieldsTheme {
        svg {
            path {
                fill: #fff;
            }
        }
    }
    &.DarkSpacesTheme {
        svg {
            path {
                fill: #fff;
            }
        }
    }
}
.MuiListItem-root {
    .active {
        svg {
            path {
                fill: #fff;
            }
        }
    }
}
.signup-success-message {
    font-size: 26px !important;
    line-height: 32px !important;
}
.swal2-title {
    font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI",
        Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
    font-size: 24px;
    margin-bottom: 15px;
}
.text-center {
    text-align: center;
}

@media (max-width: 991px) {
    .remove-padding {
        padding: 30px 0px !important;
    }
    .p-0 {
        padding: 0px !important;
    }
    .message {
        font-size: 20px !important;
        line-height: 25px !important;
        margin-bottom: 20px !important;
        margin-top: 20px !important;
    }
    .swal2-title {
        font-size: 20px;
        line-height: 28px;
    }
    .signup-success-message {
        font-size: 20px !important;
        line-height: 28px !important;
    }
    .mobile-action-btns {
        flex-wrap: wrap-reverse !important;
        .MuiButtonBase-root {
            width: 100%;
            margin-bottom: 10px;
            margin-left: 0 !important;
            margin-right: 0 !important;
        }
    }
}
.preloader {
    height: 100px;
    width: 100px;
}
.clock-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    &.PurpleFlowTheme {
        .clock {
            color: rgb(242, 169, 0);
        }
    }
    &.GreyGooseTheme {
        .clock {
            color: rgb(28, 52, 140);
        }
    }
    &.PureLightTheme {
        .clock {
            color: rgb(68, 84, 204);
        }
    }
    &.NebulaFighterTheme {
        .clock {
            color: rgb(112, 99, 192);
        }
    }
    &.GreenFieldsTheme {
        .clock {
            color: rgb(54, 132, 92);
        }
    }
    &.DarkSpacesTheme {
        .clock {
            color: rgb(242, 169, 0);
        }
    }
    .clock {
        color: #ff1943;
        &:first-child {
            &::before {
                display: none;
            }
        }
        &::before {
            content: ":";
        }
    }
}
.countdown-timer {
    display: flex;
    align-items: center;
    justify-content: center;
    .icon {
        font-size: 20px;
        margin-right: 3px;
        color: #ff1943;
    }
    &.PurpleFlowTheme {
        .icon {
            color: rgb(242, 169, 0);
        }
    }
    &.GreyGooseTheme {
        .icon {
            color: rgb(28, 52, 140);
        }
    }
    &.PureLightTheme {
        .icon {
            color: rgb(68, 84, 204);
        }
    }
    &.NebulaFighterTheme {
        .icon {
            color: rgb(112, 99, 192);
        }
    }
    &.GreenFieldsTheme {
        .icon {
            color: rgb(54, 132, 92);
        }
    }
    &.DarkSpacesTheme {
        .icon {
            color: rgb(242, 169, 0);
        }
    }
}
.material-pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px 20px;
    .MuiPagination-ul {
        li {
            .Mui-selected {
                background: rgb(68, 84, 204);
                border-color: rgb(68, 84, 204);
                color: #fff;
            }
        }
    }
    &.PurpleFlowTheme {
        .MuiPagination-ul {
            li {
                .Mui-selected {
                    background: rgb(242, 169, 0);
                    border-color: rgb(242, 169, 0);
                    color: #fff;
                }
            }
        }
    }
    &.GreyGooseTheme {
        .MuiPagination-ul {
            li {
                .Mui-selected {
                    background: rgb(28, 52, 140);
                    border-color: rgb(28, 52, 140);
                    color: #fff;
                }
            }
        }
    }
    &.PureLightTheme {
        .MuiPagination-ul {
            li {
                .Mui-selected {
                    background: rgb(68, 84, 204);
                    border-color: rgb(68, 84, 204);
                    color: #fff;
                }
            }
        }
    }
    &.NebulaFighterTheme {
        .MuiPagination-ul {
            li {
                .Mui-selected {
                    background: rgb(112, 99, 192);
                    border-color: rgb(112, 99, 192);
                    color: #fff;
                }
            }
        }
    }
    &.GreenFieldsTheme {
        .MuiPagination-ul {
            li {
                .Mui-selected {
                    background: rgb(54, 132, 92);
                    border-color: rgb(54, 132, 92);
                    color: #fff;
                }
            }
        }
    }
    &.DarkSpacesTheme {
        .MuiPagination-ul {
            li {
                .Mui-selected {
                    background: rgb(242, 169, 0);
                    border-color: rgb(242, 169, 0);
                    color: #fff;
                }
            }
        }
    }
}

.pricing-card {
    img {
        border-radius: 5px;
    }
    &.PurpleFlowTheme {
        img {
            border: 1px solid rgba(124, 65, 180, 0.7);
        }
    }
    &.GreyGooseTheme {
        img {
            border: 1px solid rgba(28, 52, 140, 0.7);
        }
    }
    &.PureLightTheme {
        img {
            border: 1px solid rgba(68, 84, 204, 0.7);
        }
    }
    &.NebulaFighterTheme {
        img {
            border: 1px solid rgba(112, 99, 192, 0.7);
        }
    }
    &.GreenFieldsTheme {
        img {
            border: 1px solid rgba(54, 132, 92, 0.7);
        }
    }
    &.DarkSpacesTheme {
        img {
            border: 1px solid rgba(162, 48, 23, 0.7);
        }
    }

    &.PurpleFlowTheme {
        border: 1px solid #e7e4e4;
    }
    &.GreyGooseTheme {
        border: 1px solid #e7e4e4;
    }
    &.PureLightTheme {
        border: 1px solid #e7e4e4;
    }
    &.NebulaFighterTheme {
        border: 1px solid transparent;
    }
    &.GreenFieldsTheme {
        border: 1px solid transparent;
    }
    &.DarkSpacesTheme {
        border: 1px solid transparent;
    }
}
.pricing-btn {
    background: 2px solid transparent !important;

    &.active {
        &.PurpleFlowTheme {
            background: transparent;
            color: rgb(242, 169, 0);
            border: 2px solid rgb(242, 169, 0) !important;
        }
        &.GreyGooseTheme {
            background: transparent;
            color: rgb(28, 52, 140);
            border: 2px solid rgb(28, 52, 140) !important;
        }
        &.PureLightTheme {
            background: transparent;
            color: rgb(68, 84, 204);
            border: 2px solid rgb(68, 84, 204) !important;
        }
        &.NebulaFighterTheme {
            background: transparent;
            color: rgb(112, 99, 192);
            border: 2px solid rgb(112, 99, 192) !important;
        }
        &.GreenFieldsTheme {
            background: transparent;
            color: rgb(54, 132, 92);
            border: 2px solid rgb(54, 132, 92) !important;
        }
        &.DarkSpacesTheme {
            background: transparent;
            color: rgb(242, 169, 0);
            border: 2px solid rgb(242, 169, 0) !important;
        }
    }
}

.accordion-wrap {
    .accordion-header {
        border-radius: 0px;
        .MuiAccordionSummary-expandIconWrapper {
            min-height: 30px;
            min-width: 30px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            background: rgb(68, 84, 204);
            color: #fff;
            border-radius: 50%;
        }
    }
    .table-items {
        padding-left: 20px;
        @media (max-width: 767px) {
            padding-left: 5px;
        }
        li {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            line-height: 28px;
            margin-bottom: 3px;
            @media (max-width: 767px) {
                font-size: 14px;
            }
            .label {
                min-width: 130px;
                line-height: 20px;
            }
        }
    }
    .accordion-body {
        padding-left: 55px;
    }
}
.responsive-table {
    &.PurpleFlowTheme {
        .accordion-wrap {
            .accordion-header {
                .MuiAccordionSummary-expandIconWrapper {
                    background: rgb(242, 169, 0);
                }
            }
        }
    }
    &.GreyGooseTheme {
        .accordion-wrap {
            .accordion-header {
                .MuiAccordionSummary-expandIconWrapper {
                    background: rgb(28, 52, 140);
                }
            }
        }
    }
    &.PureLightTheme {
        .accordion-wrap {
            .accordion-header {
                .MuiAccordionSummary-expandIconWrapper {
                    background: rgb(68, 84, 204);
                }
            }
        }
    }
    &.NebulaFighterTheme {
        .accordion-wrap {
            .accordion-header {
                .MuiAccordionSummary-expandIconWrapper {
                    background: rgb(112, 99, 192);
                }
            }
        }
    }
    &.GreenFieldsTheme {
        .accordion-wrap {
            .accordion-header {
                .MuiAccordionSummary-expandIconWrapper {
                    background: rgb(54, 132, 92);
                }
            }
        }
    }
    &.DarkSpacesTheme {
        .accordion-wrap {
            .accordion-header {
                .MuiAccordionSummary-expandIconWrapper {
                    background: rgb(242, 169, 0);
                }
            }
        }
    }
}
.swal2-modal {
    .swal2-title {
        font-size: 20px;
    }
    &.NebulaFighterTheme {
        background: #111633;
        .swal2-title,
        .swal2-html-container {
            color: #fff;
        }
    }
    &.GreenFieldsTheme {
        background: #232a2e;
        .swal2-title,
        .swal2-html-container {
            color: #fff;
        }
    }
    &.DarkSpacesTheme {
        background: #252525;
        .swal2-title,
        .swal2-html-container {
            color: #fff;
        }
    }

    &.PurpleFlowTheme {
        .swal2-confirm {
            background: rgb(242, 169, 0) !important;
        }
    }
    &.GreyGooseTheme {
        .swal2-confirm {
            background: rgb(28, 52, 140) !important;
        }
    }
    &.PureLightTheme {
        .swal2-confirm {
            background: rgb(68, 84, 204) !important;
        }
    }
    &.NebulaFighterTheme {
        .swal2-confirm {
            background: rgb(112, 99, 192) !important;
        }
    }
    &.GreenFieldsTheme {
        .swal2-confirm {
            background: rgb(54, 132, 92) !important;
        }
    }
    &.DarkSpacesTheme {
        .swal2-confirm {
            background: rgb(242, 169, 0) !important;
        }
    }
}
.editor-wrapper {
    border: 1px solid #e5e5e5;
    border-radius: 5px;
    .rdw-editor-toolbar {
        margin: 0;
        border: none;
        border-bottom: 1px solid #e5e5e5;
        background: transparent;
    }
    .rdw-editor-main {
        min-height: 150px;
        padding: 20px;
        .public-DraftStyleDefault-block {
            margin: 0;
        }
    }
    &.dark {
        border-color: rgba(255, 255, 255, 0.23);
        .rdw-editor-toolbar {
            border-color: rgba(255, 255, 255, 0.23);
            .rdw-dropdown-wrapper,
            .rdw-option-wrapper {
                background: rgba(255, 255, 255, 0.7);
                border-color: rgba(255, 255, 255, 0.5);
            }
            .rdw-dropdown-selectedtext {
                color: black;
            }
        }
    }
}
.date,
.month {
    margin: 0;
    text-align: center;
    width: 100%;
}
.responsive-settings {
    &.admin-theme-setting {
        top: 90px;
    }
}
@media (max-width: 991px) {
    .MuiGrid-container.responsive-container {
        width: 100%;
        padding-right: 18px;
        padding-left: 0px;
        margin-left: 0;
    }
    .MuiPageTitle-wrapper {
        padding: 18px !important;
        h3 {
            font-size: 20px !important;
        }
        .MuiTypography-subtitle2 {
            font-size: 14px !important;
            line-height: 20px !important;
        }
    }
    .bottom-bar {
        width: calc(100% - 18px) !important;
    }
    .responsive-settings {
        top: 15px !important;
        right: 15px !important;
        &.admin-theme-setting {
            top: 20px;
        }
        &::before {
            top: -7.5px !important;
            left: -7.5px !important;
            animation: ripple1 1s infinite !important;
            width: 50px !important;
            height: 50px !important;
            z-index: 111 !important;
            transform: scale(1.2);
        }
        .MuiFab-root {
            height: 35px !important;
            width: 35px !important;
        }
    }
}
@keyframes ripple1 {
    0% {
        transform: scale(0.8);
        opacity: 1;
    }
    100% {
        transform: scale(1.5) !important;
        opacity: 0;
    }
}
.package-title {
    text-align: center;
    .title {
        margin: 0px 0px 9px;
        font-weight: 700;
        font-size: 35px;
        line-height: 1.167;
    }
    p {
        font-size: 18px;
        margin-bottom: 20px;
        margin-top: 0;
    }
}
.pricing-info {
    margin-bottom: 25px;
    li {
        display: flex;
        align-items: center;
        line-height: 25px;
        color: rgba(34, 51, 84, 0.7);
        svg {
            font-size: 20px;
            margin-right: 7px;
        }
    }
}
.MuiCardContent-root {
    padding: 15px !important;
}
.qrcode-wrap {
    line-height: 0;
    &.PurpleFlowTheme {
        border: 2px solid rgb(242, 169, 0);
    }
    &.GreyGooseTheme {
        border: 2px solid rgb(28, 52, 140);
    }
    &.PureLightTheme {
        border: 2px solid rgb(68, 84, 204);
    }
    &.NebulaFighterTheme {
        border: 2px solid rgb(112, 99, 192);
    }
    &.GreenFieldsTheme {
        border: 2px solid rgb(54, 132, 92);
    }
    &.DarkSpacesTheme {
        border: 2px solid rgb(242, 169, 0);
    }
}

.qrcode-modal-title {
    &.PurpleFlowTheme {
        color: rgb(242, 169, 0);
    }
    &.GreyGooseTheme {
        color: rgb(28, 52, 140);
    }
    &.PureLightTheme {
        color: rgb(68, 84, 204);
    }
    &.NebulaFighterTheme {
        color: rgb(112, 99, 192);
    }
    &.GreenFieldsTheme {
        color: rgb(54, 132, 92);
    }
    &.DarkSpacesTheme {
        color: rgb(242, 169, 0);
    }
}
.dio-title {
    text-decoration: none;
    color: rgba(203, 204, 210, 0.7);
    &.PurpleFlowTheme,
    &.GreyGooseTheme,
    &.PureLightTheme {
        color: rgba(34, 51, 84, 0.7);
    }
}
.proposal-menu {
    &.PurpleFlowTheme,
    &.GreyGooseTheme,
    &.PureLightTheme {
        li {
            a {
                color: rgba(34, 51, 84, 0.7);
            }
        }
    }
    &.PurpleFlowTheme {
        li {
            a {
                &.active {
                    color: rgb(242, 169, 0);
                }
            }
        }
    }
    &.GreyGooseTheme {
        li {
            a {
                &.active {
                    color: rgb(28, 52, 140);
                }
            }
        }
    }
    &.PureLightTheme {
        li {
            a {
                &.active {
                    color: rgb(68, 84, 204);
                }
            }
        }
    }
    &.NebulaFighterTheme {
        li {
            a {
                &.active {
                    color: rgb(112, 99, 192);
                }
            }
        }
    }
    &.GreenFieldsTheme {
        li {
            a {
                &.active {
                    color: rgb(54, 132, 92);
                }
            }
        }
    }
    &.DarkSpacesTheme {
        li {
            a {
                &.active {
                    color: rgb(242, 169, 0);
                }
            }
        }
    }
    li {
        margin-bottom: 5px;
        &:last-child {
            margin-bottom: 0;
        }
        a {
            display: inline-block;
            color: rgba(203, 204, 210, 0.7);
            text-decoration: none;
            &.active {
                font-weight: 600;
            }
        }
    }
}
.proposal-action {
    display: flex;
    align-items: center;
    li {
        margin-right: 12px;
        &:last-child {
            margin-right: 0;
        }
        .search-proposal {
            .MuiInputBase-root {
                border-radius: 40px;
                input {
                    padding: 11.5px 15px;
                }
            }
        }
    }
}
.proposal-content {
    display: block;
    color: rgba(203, 204, 210, 0.7);
    font-size: 14px;
    text-decoration: none;
    h3 {
        margin-bottom: 5px;
    }
    p {
        margin-top: 0;
    }
    .date {
        font-weight: 600;
        span {
            &.end {
                color: #ff1943;
            }
        }
    }
    &.PurpleFlowTheme {
        color: rgba(34, 51, 84, 0.7);
        .date {
            span {
                &.start {
                    color: rgb(242, 169, 0);
                }
            }
        }
    }
    &.GreyGooseTheme {
        color: rgba(34, 51, 84, 0.7);
        .date {
            span {
                &.start {
                    color: rgb(28, 52, 140);
                }
            }
        }
    }
    &.PureLightTheme {
        color: rgba(34, 51, 84, 0.7);
        .date {
            span {
                &.start {
                    color: rgb(68, 84, 204);
                }
            }
        }
    }
    &.NebulaFighterTheme {
        .date {
            span {
                &.start {
                    color: rgb(112, 99, 192);
                }
            }
        }
    }
    &.GreenFieldsTheme {
        .date {
            span {
                &.start {
                    color: rgb(54, 132, 92);
                }
            }
        }
    }
    &.DarkSpacesTheme {
        .date {
            span {
                &.start {
                    color: rgb(242, 169, 0);
                }
            }
        }
    }
}
.proposal-item {
    margin-bottom: 15px;
    li {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 8px 15px;
        border-radius: 5px;
        background: rgba($color: #000000, $alpha: 0.1);
        font-size: 14px;
        margin-bottom: 2px;
        span {
            font-size: 13px;
            &.info {
                span {
                    opacity: 0.8;
                }
            }
        }
        &:last-child {
            margin-bottom: 0;
        }
    }
}
.wallet-items {
    &.PurpleFlowTheme,
    &.GreyGooseTheme,
    &.PureLightTheme {
        .wrapper {
            .content-wrap {
                .content {
                    .title {
                        a {
                            color: rgba(34, 51, 84, 0.7);
                        }
                    }
                }
            }
            .info {
                .price {
                    color: rgba(34, 51, 84, 0.7);
                }
            }
        }
    }

    .wrapper {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        padding: 15px 20px;
        .content-wrap {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            .image {
                margin-right: 15px;
            }
            a {
                display: inline-block;
                text-decoration: none;
            }
            span {
                display: block;
            }
            .content {
                .title {
                    font-size: 16px;
                    a {
                        font-weight: 600;
                        color: rgba(203, 204, 210, 0.7);
                    }
                }
            }
        }
        .info {
            text-align: right;
            .price {
                display: block;
                font-size: 16px;
                font-weight: 600;
                color: rgba(203, 204, 210, 0.7);
            }
            .price-label {
                font-size: 13px;
            }
        }
    }
}
.link {
    text-decoration: none;
}
.avatar-dao {
    img {
        object-fit: contain !important;
    }
}
.proposal-countdown {
    margin-bottom: 0px;
    span {
        font-size: 33px;
        font-weight: 600;
    }
    &.clock-wrap {
        .clock {
            display: flex;
            align-items: center;
            &::before {
                font-size: 33px;
                margin-top: -6px;
            }
        }
    }
}
.light {
    .ql-snow .ql-picker-label {
        color: rgba(203, 204, 210, 0.7);
    }
    .ql-snow .ql-stroke {
        stroke: rgba(203, 204, 210, 0.7) !important;
    }
}
.dark {
    .ql-snow .ql-picker-label {
        color: rgba(34, 51, 84, 0.7);
    }
    .ql-snow .ql-stroke {
        stroke: rgba(34, 51, 84, 0.7) !important;
    }
}
.dao-about {
    color: rgba(203, 204, 210, 0.7);
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    strong {
        color: #cbccd2;
    }
    p {
        span {
            i {
                color: #cbccd2;
            }
        }
    }
    p {
        line-height: 22px !important;
    }

    &.PurpleFlowTheme,
    &.GreyGooseTheme,
    &.PureLightTheme {
        color: rgba(34, 51, 84, 0.7);
        h1,
        h2,
        h3,
        h4,
        h5,
        h6,
        strong {
            color: rgba(34, 51, 84, 0.7);
        }
        p {
            span {
                i {
                    color: rgba(34, 51, 84, 0.7);
                }
            }
        }
    }
}
.details {
    p {
        span,
        strong {
            background: transparent !important;
        }
    }
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p {
        span {
            background: transparent !important;
        }
    }
}
