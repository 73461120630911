.w-full{
    width: 100%;
}
.rdw-editor-main{
    min-height: 96px !important;
}
/* .rdw-editor-toolbar{
    display: none ;
}
.rdw-editor-main:focus + .rdw-editor-toolbar {
    display: block !important;
} */

.p-4 {
    padding: 0 16px 16px 16px;
}

.qr-un-text {
    padding: 16px;
}

.px {
    padding: 0 16px;
}

.pt-4 {
    padding-top: 16px;
}
.bg-none{
    background-color: "#040506" !important;
    box-shadow: none !important;
}
.cursor{
    cursor: pointer;
}
.d-flex{
    display: flex;
}

.w-full{
    width: 100%;
}
.justify-between{
    justify-content: space-between;
}
.p-none{
    padding: 1px !important;
    padding-top: 4px !important;
}
